@import 'components/colors';
$fontFamilyMono: 'Anonymous Pro', monospace;

// Libraries
@import 'libs/bulma/bulma.sass';
@import 'libs/bulma-divider.sass';
@import 'libs/bulma-steps.sass';

$standardPadding: 1.6rem;
$sidebarWidth: 15rem;
$topbarHeight: 3.25rem;
$floatingWindowBackground: rgba(255, 255, 255, 0.95);
$floatingWindowWidth: 550px;
$floatingShadow: 0 0 27px rgba(27, 27, 27, 0.27);
$announcementsHeight: 2rem;

// Components
@import 'components/topbar';
@import 'components/table';
@import 'components/googleSignIn';
@import 'components/tooltip';
@import 'components/logo';
@import 'components/commons';
@import 'components/pages';
@import 'components/login';
@import 'components/search';
@import 'components/shadow';
@import 'components/settlementPlan';
@import 'components/forms';
@import 'components/finantialEntities';
@import 'components/liquidationLanding';
@import 'components/quickview.sass';
@import 'components/steps.sass';
@import 'components/modal';
@import 'components/collapsible';

*,
*:before,
*:after {
  box-sizing: border-box;
}

button {
  background: transparent;
  appearance: none;
  border: transparent;
}

//  Global settings
#app {
  position: relative;
  display: block;
  min-height: 100%;
  height: 100%;
  min-width: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

#app-content {
  display: block;
  height: 100%;
  min-height: 100%;
  z-index: 0;
  width: 100%;
  padding-top: $topbarHeight;
}

#app-sidebar {
  box-shadow: 0 0 6px rgba($dark-black, 0.5);
  height: 100%;
  margin-top: $topbarHeight;
  padding: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  width: $sidebarWidth;
}

.column p {
  margin-bottom: 1em;
}

.avatar {
  width: 20px;
  margin-right: 0.5em;
  border-radius: 50%;
}

.alert-success {
  color: green;
  font-size: 1.5rem;
}

.alert-warning {
  color: yellow;
  font-size: 1.5rem;
}

.alert-error {
  color: red;
  font-size: 1.5rem;
}

.field.has-addons {
  display: flex;
  justify-content: flex-start;
}

.inbox-messages {
  margin-top: 60px;
}

.messages {
  padding-top: 0;
}

.inbox-messages .card {
  width: 100%;
}

.card-content {
  padding: 1.5rem;
}

.inbox-messages .msg-timestamp {
  float: right;
  padding: 0 20px;
  color: #5d5d5d;
}

.inbox-messages .msg-attachment {
  float: right;
}

.inbox-messages .msg-subject {
  padding: 10px 0;
  color: #5d5d5d;
}

.inbox-messages strong {
  color: #5d5d5d;
}
.inbox-messages .msg-snippet {
  padding: 5px 20px 0px 5px;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  background: blue;
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.box.selected {
  box-shadow: 0 2px 3px hsla(0, 0%, 4%, 0.1), 0 0 0 1px $link;
}

.payable {
  position: absolute;
  right: 0;
  margin: 20px;
  color: green;
}

.table th {
  font-weight: 300;
}

.table.no-link tbody tr {
  cursor: default;
}

.loading {
  align-content: center;
  color: $link;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: 3rem;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-between-2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.scroll-x {
  overflow-x: scroll;
}

.spin-icon {
  animation: spin-icon 2s infinite linear;
}
@-webkit-keyframes spin-icon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes spin-icon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.login-with-google {
  background-color: $primary;
  font-family: 'Open Sans',sans-serif;
  font-size: 16px;
  box-sizing: border-box;
  text-decoration: none;
  padding: 0 10px;
  color: #fff;
  border-radius: 3px;
  border: 1px solid white;
  box-shadow: #b5b5b5 0 1px 2px;
  margin: 5px 5px 0;
  width: 220px;
  height: 35px;
  display: inline-block;
  span {
    display: inline-block;
    line-height: 32px;
  }
}

.react-datetime-picker__wrapper {
  border: none !important;
}

.flex {
  display: flex;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.editor {
  border: 1px solid #F1F1F1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
  height: 80% !important;
}

.etoolbar {
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid #F1F1F1;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  user-select: none;
}

.ewrapper {
  height: 100%
}

.open-folder {
  border-left: 2px #1d4567 solid;
  td:first-of-type {
    font-weight: bold;
  }
}

.folder-table tbody tr td {
  padding: 8px 12px;
}

.some-class {
    border: 1px solid transparent;
    border-radius: 2px!important;
    color: #fff;
    font-size: 18px;
    font-variant-ligatures: no-contextual;
    height: 25px;
    line-height: 22px;
    margin: 0;
    min-width: 1px;
    padding: 2px 7px;
    background: #efefef;
    margin-bottom: 10px;
    color: black;
}

a[disabled] {
  pointer-events: none;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0px !important;
  padding-right: 62px;
}

.document-button {
  display: flex;
}

.document-button :first-child {
  margin-right: 0.5rem;
}
