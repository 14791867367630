.table {
  thead tr th {
    font-weight: 700;
    color: #000;
    padding: 1.2rem 0.7rem;
    line-height: 100%;
  }
  tbody tr {
    cursor: pointer;
  }
  tbody tr td {
    padding: 1.2rem 0.7rem;
    line-height: 100%;
  }
}

.waiting-for-data div {
  width: 100%;
  display: inline-block;
  background-color: #007da4;
  height: 16px;
  animation-name: waitingForDataAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes waitingForDataAnimation {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.8;
  }
}

.hr {
  padding-bottom: 1rem;
  border-bottom: 1px solid #cdcdcd;
}

.table-sticky-thead thead tr {
  position: sticky;
  top: 3rem;
  z-index: 1;
  background-color: whitesmoke;
}
