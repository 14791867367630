$resuelveLogo-height: 2rem;

#app-topbar {
  z-index: 40;
  #resuelve-logo {
    display: inline-block;
    height: 2.5rem;
    padding: 0.25rem 0;
  }
}

.notification-card {
  cursor: pointer;
  border-bottom: solid 1px #ccc;
}

.notification-card:hover {
  background-color: whitesmoke;
}
