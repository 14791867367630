.liquidation-content {
  padding: 60px;
  top: 197px;
  color: #000;
  background-color: white;
}

.liquidation-pane {
  display: block;
  width: 50%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
