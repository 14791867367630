$buttonIcon-margin: 0.5rem;

.mdi {
  font-size: large;
}

button {
  > i {
    margin-left: 0;
    margin-right: $buttonIcon-margin;
  }
}

.card-footer-item {
  > i {
    margin-left: 0;
    margin-right: $buttonIcon-margin;
  }
}

.is-bold {
  font-weight: bold !important;
}

.subtitle {
  text-transform: uppercase;
  font-weight: bold;
  font-size: small;
}

.m-r-15 {
  margin-right: 15px;
}

.m-t-15 {
  margin-top: 15px;
}

.overlay {
  background-color: #6f6f6f;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0.4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.is-fullheight {
  height: 100%;
}

.is-flex-column {
  display: flex;
  flex-direction: column;
}

.use-remaining-space {
  flex-grow: 1;
}

.is-scrollable {
  overflow: auto;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-around {
  display: flex;
  justify-content: space-around;
}

.pre-wrap {
  font-size: 0.8rem;
  white-space: pre-wrap;
}

.no-select {
  user-select: none;
}

.text-center {
  text-align: center;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.margin-left-05-rem {
  margin: 0 0.5em;
}

.margin-right-1-rem {
  margin-right: 1em !important;
}

.margin-bottom-1-rem {
  margin-bottom: 1em;
}

.margin-top-1-rem {
  margin-top: 1em;
}

.iframe-loading {
  background: url("https://gifimage.net/wp-content/uploads/2017/08/loading-gif-transparent-4.gif") no-repeat center
}

.disabled {
  pointer-events: none;
}

.no-wrap {
  white-space: nowrap; 
}

.cursor-default {
  cursor: default !important;
}

.no-padding-bottom {
  padding-bottom: 0px;
}

.no-margin-bottom {
  margin-bottom: 0px !important;
}

.no-text-decoration {
  text-decoration: none;
}

.has-ellipsis-text-overflow {
  text-overflow: ellipsis;
}

.has-separate-row {
  border-collapse: separate;
  border-spacing: 0 15px;

  tbody {
    tr {
      cursor: pointer !important;
    }
  }
}

.align-content-end {
  justify-content: flex-end;
  display: flex;
}

.width-auto {
  width: auto !important;
}

.ribbon {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  align-items: center;
  position: absolute;
  top: 3rem;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1rem;
}

.ribbon img{
  width: auto;
  padding: 0.5rem;
}

.branding{
  position: fixed;
  bottom: 0.5rem;
  padding: 0 1rem;
  pointer-events: none;
  right: 0.5rem;
  z-index: 2;
}

.tags-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

.overdue{
  padding:5px 8px;
  background:#FF6A26;
  border-radius: 6px;
}

div.react-datepicker-popper {
  z-index: 40;
}

@media (max-width: 575.98px) {
  .ribbon{
    top: 6rem;
    left: 15rem;
  }
}


@media (max-width: 768px) {
  .ribbon{
    top: 6rem;
  }
}