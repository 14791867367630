.settlement-plan{
  .info{
    margin-top: 4rem;
    background: white;
    padding-top: 2rem;
    border-radius: 2px;
  }

  td, th{
    text-align: center;
  }

  .range{
    width: 100%;
    margin: 3rem auto;

    &:first-child{
      float: right;
    }

    input[type="range"]{
      width: 100%;
      display: block;
      -webkit-appearance: none;
      background: #111820;
      height: 10px;
      border-radius: 5px;
      border: inset;

    }
    input[type=range]::-webkit-slider-thumb {
      -webkit-appearance: none;
      background: white;
      height: 50px;
      width: 50px;
      padding: 1rem;
      border-radius: 50%;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      transition: all 0.3s cubic-bezier(.25,.8,.25,1);
      background-size: 60%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .detail{
    background: #015289;
    padding: 2rem;
    color: white !important;
    border-radius: 4px;
    height: 160px;
    margin-bottom: 2rem;

    .title{
        color: white;
    }
  }

  .save{
    background: white;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    float: right;
    margin-right: -30px;
    margin-top: -90px;
    border: none;

    &:hover{
      cursor: pointer;
    }

    i{
      font-size: 3.6rem;
      margin-right: 0;
      color: #111820;
    }

  }
  .recharts-responsive-container{
    margin: 0 auto;
  }

  .primary{
    background: #015289;
    color: white;
  }
}
