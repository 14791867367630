#page-login {
  position: relative;
  background-position: center;
  background-size: cover;
  min-height: 100%;
  height: 100%;

  /* Fixes position bug */
  &:before {
    position: relative;
    display: block;
    content: '';
    width: 1px;
    height: 1px;
    pointer-events: none;
  }

  .login-content {
    position: relative;
    width: 100%;
    padding: 2rem $standardPadding;
    background-color: $floatingWindowBackground;
    box-shadow: $floatingShadow;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    #enconta-logo {
      position: relative;
      display: block;
      width: 80%;
      max-width: 250px;
      margin: 1rem auto 2rem;
      color: red;
    }
  }

  .login-top-2rem {
    margin-top: 2rem;
  }
}
