.collapsible {
  margin-bottom: 4px !important;

  .collapsible-header {
    border-radius: 0;
    cursor: pointer;
  }

  .collapsible-body {
    padding: 0 !important;
  }
}
