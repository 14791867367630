.discount {
  align-items: center;
  background-color: rgba($color-primary, 0.2);
  border: 1px solid white;
  display: flex;
  span {
    font-weight: bold;
    margin-right: 0.5rem;
  }
  button {
    margin-left: 0.5rem;
  }
}
