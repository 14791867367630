$color-primary: #007DA4;
$color-primary-contrast: #FFFFFF;
$color-primary-hover: #1FCAFF;
$color-secondary: #003764;
$color-secondary-hover: #003764;

$light-grey: #F7F7F7;
$light-grey-alt: #F0F0F0;
$light-grey-hover: #E0E0E0;

$medium-grey: #999999;

$dark-grey: #474747;
$dark-grey-alt: #272727;
$dark-grey-hover: #373737;

$dark-black: #171717;

$color-scheme-main-1: #94C941;
$color-scheme-main-2: #12A84C;
$color-scheme-main-3: #00AEAB;
$color-scheme-main-4: #0D81A1;
$color-scheme-main-5: #DE208C;
$color-scheme-main-6: #111820;
$color-scheme-alt-1: #83D0D0;
$color-scheme-alt-2: #1B9DB2;
$color-scheme-alt-3: #E0569F;
$color-scheme-alt-4: #565656;
$color-scheme-alt-5: #919395;
$color-scheme-alt-6: #CCCCCC;
$color-scheme-alt-7: #F1F1F1;
$color-scheme-error: #EC1C24;
